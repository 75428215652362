<template>
  <section class="invoice-preview-wrapper">
    <b-alert variant="danger">
      <h4 class="alert-heading">
        {{ $t("fields.errorfetch") }} {{ modulename }}
        {{ $t("fields.details") }}
      </h4>
      <div class="alert-body">
        <b-link class="alert-link" :to="{ name: GENAPP_APP_STORE_MODULE_NAME }">
          No {{ modulename }} {{ $t("fields.detailsnotfount") }}
        </b-link>
      </div>
    </b-alert>
    <b-card no-body class="invoice-preview-card">
      <b-card-body class="d-print-block d-none invoice-padding pb-0">
        <div
          class="d-flex justify-content- flex-md-row flex-column invoice-spacing mt-0"
        >
          <div>
            <div class="logo-wrapper">
              <logo style="height: 10vw; wipricing: 10vw" />
              <h3 class="text-primary invoice-logo">FRP</h3>
            </div>
          </div>
        </div>
      </b-card-body>
      <h5
        class="py-2 text-uppercase text-center bg-dark text-white"
        v-if="period"
      >
        {{ $t("fields.period") }} - <span> {{ period.periodname || "" }} </span>
      </h5>
      <b-card-body class="invoice-padding pb-0">
        <div class="table-responsive" v-if="idx && idx.length > 0">
          <table>
            <tr>
              <td
                v-for="(item, index) in idx"
                :class="
                  period.periodname == item.name ? 'btn-dark rounded' : ''
                "
                :key="'btnx' + index"
                v-on:click="updateperiod(item.metadata)"
              >
                <a style="line-height: 1.5rem" class="btn px-1">
                  {{ item.name }}
                </a>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="budgetdata && budgetdata.length > 0">
          <div>
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th style="width: 20%">Budget</th>
                  <th style="width: 20%">Incurred</th>
                  <th style="width: 20%">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in budgetdata"
                  v-on:click="updatemodaldata(item);modalShow=1"
                  :key="'budgetlist' + index"
                >
                  <th>{{ item.key }}</th>
                  <td>₹ {{ item.budget || 0 }}</td>
                  <td>₹ {{ item.cost || 0 }}</td>
                  <td>
                    ₹
                    {{
                      parseFloat(item.budget || 0) - parseFloat(item.cost || 0)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="text-center align-middle h-100">
            No budget for this month, please come next month for the updated
          budget
        </div>
      </b-card-body>
    </b-card>


    <b-card no-body class="invoice-preview-card mt-2">
      <b-card-body class="d-print-block d-none invoice-padding pb-0">
        <div
          class="d-flex justify-content- flex-md-row flex-column invoice-spacing mt-0"
        >
          <div>
            <div class="logo-wrapper">
              <logo style="height: 10vw; wipricing: 10vw" />
              <h3 class="text-primary invoice-logo">FRP</h3>
            </div>
          </div>
        </div>
      </b-card-body>
      <h5
        class="py-2 text-uppercase text-center bg-dark text-white"
        v-if="period">
       Committed Budgets
      </h5>
      <b-card-body class="invoice-padding pb-0">
        <div v-if="commiteddata">
          <div>
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th style="width: 20%">Budget</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >EMI</th>
                  <td class="text-success">₹ {{ commiteddata['emi'] || 0}}</td>
                </tr>
                <tr>
                  <th>Salary - Support Staff</th>
                  <td class="text-success">₹ {{ commiteddata['charges'] || 0}}</td>
                </tr>
                <tr>
                  <th>Rent</th>
                  <td class="text-success">₹ {{ commiteddata['rent'] || 0}}</td>
                </tr>
                <!-- <tr>
                  <th>Premium</th>
                  <td>₹ {{ commiteddata['premium'] || 0}}</td>
                </tr> -->
              </tbody>

            </table>
          </div>
        </div>
        <div v-else class="text-center align-middle h-100">
            No Committed Budgets records found

        </div>
      </b-card-body>
    </b-card>
    <b-modal
      size="xl"
      id="modal-1"
      title="Basic Modal"
      v-model="modalShow"

      hide-footer
          >
      <b-card-text>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th style="width: 15%">Name</th>
              <th class="text-center" style="width: 45%">Budget</th>
              <th class="text-right" style="width: 20%">Incurred</th>
              <th class="text-right" style="width: 20%">Net</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in datax" :key="'budgetsubx' + index">
              <th>
                {{ item.data.expensesubname }}
              </th>
              <td class="text-right">
                <span v-if="managechange.id != item.id">
                  ₹ {{ item.data.budget || 0 }}
                  <b-button
                    v-on:click="editbudget(item, index)"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon mx-1"
                    type="submit"
                  >
                    <feather-icon icon="Edit3Icon" class="mr-25" />
                  </b-button>
                </span>
                <span v-else>
                  <inputtext
                    name="budget"
                    :tooltip="$t('fields.enter') + ' ' + $t('fields.budget')"
                    :valuex="managechange.value"
                    @updatedata="
                      (val) => {
                        managechange.value = val;
                      }
                    "
                    validations="amount"
                  >
                  </inputtext>
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      v-on:click="saveeditbudget()"
                    >
                      <feather-icon icon="Edit3Icon" class="mr-25" />
                      Save
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="danger"
                      v-on:click="canceleditbudget()"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      Cancel
                    </b-button>
                  </b-button-group>
                </span>
              </td>
              <td class="text-right">₹ {{ item.data.cost || 0 }}</td>
              <th class="text-right">
                ₹ {{ (item.data.budget || 0) - (item.data.cost || 0) }}
              </th>
            </tr>
          </tbody>
        </table>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import viewsidelist from "@/views/Component/viewsidelist.vue";
import inputvselect from "@/views/Component/Input/inputvselect.vue";

import {
  BAvatar,
  BRow,
  BCol,
  BFormRadioGroup,
  BImg,
  BAlert,
  BFormInvalidFeedback,
  BLink,
  BCardText,
  VBTooltip,
  BFormInput,
  BFormGroup,
  BFormRadio,
  BProgress,
  BCard,
  BTable,
  BTableLite,
  BCardBody,BButtonGroup,
  BForm,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import DocumentList from "@/views/Component/DocumetsList/DocumentList.vue";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import {
  required,
  minvalue,
  amount,
  min,
  email,
  integer,
  alphaNum,
} from "@validations";
import inputtext from "@/views/Component/Input/inputtext.vue";

import useList from "./useList";
import { useToast } from "vue-toastification/composition";
import Logo from "@core/layouts/components/Logo.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCol,
    BCard,
    BFormInvalidFeedback,
    BProgress,
    BFormRadioGroup,
    BAvatar,
    // BCardCode,
    BFormRadio,BButtonGroup,
    viewsidelist,
    inputtext,
    BRow,
    BFormGroup,
    BCardBody,
    VBTooltip,
    BFormInput,
    BCardText,
    BForm,
    BTable,
    BImg,
    BCardBody,
    BTableLite,
    BButton,
    ToastificationContent,
    BAlert,
    inputvselect,
    ValidationProvider,
    ValidationObserver,
    DocumentList,
    BLink,
    Logo,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      required,
      amount,
      email,
      alphaNum,
      integer,
      minvalue,
      min,
      datax: [],
      index: -1,
      changebudget: 0,
      managechange: {
        index: -1,
        id: null,
        value: null,
      },
      modalShow:0,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "budget";
    const modulename = "Budget";
    const modulenamesub = "budget";
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    const d = new Date();
    let month = d.getMonth();
    let year = d.getFullYear();
    var monthoptions = [];
    var mm = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const { refFormObserver, getValidationState } = formValidation();
    const toast = useToast();
    const appData = ref(null);
    var adderror = null;

    var idx = ref([]);
    var budgetdata = ref([]);
    var commiteddata = ref([]);
    let period = ref(null);
    let periodsearch = ref(null);

    const printview = () => {
      window.print();
    };
    let modalShow = false;
    const { deletefun } = useList();
    let getdata = () => {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          period: period.value ? period.value.periodfullname : null,
        })
        .then((response) => {
          if (!response.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
              })
              .catch(() => {});
          }
          period.value = response.data.period;
          budgetdata.value = response.data.budget;
          commiteddata.value = response.data.commiteddata;
          idx.value = response.data.id.reverse();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          router
            .push({
              name: GENAPP_APP_STORE_MODULE_NAME,
            })
            .catch(() => {});
        });
    };
    let editdata = (val) => {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/editData", val)
        .then((response) => {

          getdata()
        })
        .catch((error) => {

        });
    };
    getdata();

    return {
      GENAPP_APP_STORE_MODULE_NAME,
      deletefun,
      modulename,
      modulenamesub,
      appData,
      printview,
      modalShow,
      refFormObserver,
      getValidationState,
      period,
      periodsearch,
      getdata,
      editdata,
      monthoptions,
      adderror,
      idx,
      budgetdata,
      commiteddata
    };
  },
  methods: {
    updatemodaldata(val) {
      this.datax = val.values.data;
    },
    saveeditbudget(){
        var temp={
        index: -this.managechange.index || '',
        id: this.managechange.id || '',
        value: this.managechange.value || '',
      }
        this.editdata(temp)
        this.modalShow=0
        this.canceleditbudget()
    },
    editbudget(item, index) {
      this.managechange.index = index;
      this.managechange.id = item.id;
      this.managechange.value = item.data.budget;
    },
    canceleditbudget(){
        this.managechange={
        index: -1,
        id: null,
        value: null,
      }
    },
    updateperiod(val) {
      this.period = val;
      this.getdata();
    },
    changetype(type) {
      this.appData.amount = "";
      this.appData.type = type;
    },
    managefloat(name, val) {
      this.$set(this.appData, name, parseFloat(val));
    },
    checkdeletefun(val) {
      var self = this;
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deletefun(val);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: this.modulename + " has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          self.$router.push({
            name: this.GENAPP_APP_STORE_MODULE_NAME,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  a {
    color: black;
    text-decoration: none !important;
  }

  .d-print-block {
    display: block !important;
  }

  .content-header-right {
    display: none;
  }

  .invoice-actions {
    display: none;
  }

  .breadcrumbs-top {
    display: none;
  }

  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-wipricing: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-wipricing: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
